import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './index.less'
import logo from '../../assets/images/logo.png'
import { useState } from 'react'
import qrcode from '../../assets/images/kaiwenQRcode.png'

function Header() {
  const location = useLocation()
  const [showQRCode, setShowQRCode] = useState(false)

  const navItems = [
    { key: '/', label: '首页' },
    // { key: '/product', label: '产品', hasDropdown: true },
    { key: '/technology', label: '技术优势' },
    { key: '/about', label: '关于我们' },
    // { key: '/cooperation', label: '生态合作' },
    // { key: '/price', label: '价格' },
    { key: 'contact', label: '联系销售', isQRCode: true }, // 修改这一项
  ]

  return (
    <header className="header">
      <img className="logoImg" src={logo} alt="logo" />
      <nav className="nav-menu">
        {navItems.map((item) =>
          item.isQRCode ? (
            <span
              key={item.key}
              className="nav-item"
              onClick={() => setShowQRCode(true)}
              style={{ cursor: 'pointer' }}>
              {item.label}
            </span>
          ) : (
            <Link
              key={item.key}
              to={item.key}
              className={`nav-item ${
                location.pathname === item.key ? 'active' : ''
              }`}>
              {item.label}
            </Link>
          )
        )}
      </nav>
      <a
        className="start-btn"
        href="http://zy.xiangchaopai.com/"
        target="_blank"
        rel="noopener noreferrer">
        开始创作
      </a>

      {showQRCode && (
        <div className="qrcode-modal" onClick={() => setShowQRCode(false)}>
          <div className="qrcode-content" onClick={(e) => e.stopPropagation()}>
            <h3>扫码联系销售</h3>
            <img src={qrcode} alt="销售二维码" />
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
