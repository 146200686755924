import React from 'react'
import './index.less'
import technologyCenter from '../../assets/images/technology_center.png'
import technologyHuman from '../../assets/images/technology_human.png'

function Technology() {
  const TopSection = () => {
    return (
      <div className="technology-top">
        <div className="technology-title">技术优势</div>
        <div className="technology-content">
          技术核心团队平均学历博士以上，<br></br>
          在深度学习与图像学领域有着深厚的基数积累。<br></br>
          现有员工70%为研发人员。
        </div>
        <img className="technology-img" src={technologyCenter}></img>
      </div>
    )
  }

  const BottomSection = () => {
    const topRow = [
      {
        number: '01',
        title: '计算机图形学',
        desc: '让数字人和我们一样，拥有真实的形象',
      },
      {
        number: '02',
        title: '实时网络',
        desc: '让数字人创造实时高效的“工作环境”',
      },
    ]

    const bottomRow = [
      {
        number: '03',
        title: '神经渲染',
        desc: '让数字人和您一样思考、互动',
      },
      {
        number: '04',
        title: '人工智能',
        desc: '让数字人的对话更加自然流利',
      },
      {
        number: '05',
        title: '云计算',
        desc: '实时互动，让数字人随时随地与您建立连接',
      },
    ]

    return (
      <div className="technology-bottom">
        <div className="technology-list">
          <div className="items-row top-row">
            {topRow.map((item, index) => (
              <div className="tech-item" key={index}>
                <div className="number">{item.number}</div>
                <div className="title">{item.title}</div>
                <div className="desc">{item.desc}</div>
              </div>
            ))}
            <img className="technology-human" src={technologyHuman}></img>
          </div>
          <div className="items-row">
            {bottomRow.map((item, index) => (
              <div className="tech-item" key={index}>
                <div className="number">{item.number}</div>
                <div className="title">{item.title}</div>
                <div className="desc">{item.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="technology">
      <TopSection />
      <BottomSection />
    </div>
  )
}

export default Technology
