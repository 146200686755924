import React from 'react'
import './index.less'
import copyrightIcon from '../../assets/images/copyRight.png'

function Footer() {
  return (
    <div className="footer">
      <img src={copyrightIcon} className="copyright-icon"></img>
      <div className="copyright-text">
        2024 杭州琪美网络科技有限公司. All Right Reserved
      </div>
    </div>
  )
}

export default Footer
