import { BrowserRouter } from 'react-router-dom'
import Router from './router'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <BrowserRouter>
      <div className="app-container">
        <Header />
        <Router />
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
