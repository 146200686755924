import React from 'react'
import './index.less'
import emailIcon from '../../assets/images/email.png'
import mapImage from '../../assets/images/map.png'
import aboutBg from '../../assets/images/about_bg.png'
import aboutShadow from '../../assets/images/about_shadow.png'
function About() {
  return (
    <div className="about">
      <div className="about-title">关于我们</div>
      <div className="about-text">
        杭州琪美网络科技有限公司成立于2022年2月，是专业的AI数字智能数字人内容/直播SaaS一站式解决方案服务商，公司利用先进AI+数据打造视频内容生产SaaS平台，为直播和短视频内容生产提供AI智能数字人SaaS服务的全链路服务。
        <br></br>
        <br></br>
        公司核心创始团队均来自阿里，360，旷世科技等，均在重要岗位担任高级技术专家。琪美网络科技坚持围绕“AI数字人智能科技服务”的核心，充分发挥AI智能服务能力，坚持以客户为中心、以奋斗者为本的企业价值观，“坚持创新、团结分享、拥抱变化”的企业精神，积极承担社会责任，创造社会价值，助力共同富裕的美好远景，逐步成长为受人尊敬的企业。
      </div>

      <div className="about-info">
        <div className="info-card address">
          <div className="info-title">联系地址</div>
          <img className="map-icon" src={mapImage} alt="地图" />
        </div>

        <div className="info-card email">
          <img className="email-icon" src={emailIcon} alt="邮箱图标" />
          <div className="info-title">联系邮箱</div>
          <div className="email-text">174525112@qq.com</div>
        </div>
      </div>
      <img className="about-bg" src={aboutBg}></img>
      <img className="about-shadow" src={aboutShadow}></img>
    </div>
  )
}

export default About
